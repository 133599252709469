import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentDivider.figmaUrl.ios} codeUrl={checklists.componentDivider.codeUrl.ios} checklists={checklists.componentDivider.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`The `}<inlineCode parentName="p">{`LGNDivider`}</inlineCode>{` is a custom view designed to visually separate content in a user interface.
It provides flexibility in appearance, allowing for both text and line dividers, and can be customized to fit various design needs.`}</p>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`To use the Legion iOS UIKit theme, you need to import one of the available themes. Currently, the following themes are supported: ThemeAGR, ThemeEazy, ThemeIHS, ThemeLGN, and ThemeMyTEnS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import ThemeLGN
`}</code></pre>
    <h3><inlineCode parentName="h3">{`LGNDivider`}</inlineCode>{` Basic`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/divider/ios-uikit-divider-basic.png",
      "alt": "LGNDivider Basic"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let lgnDivider = LGNDivider()
`}</code></pre>
    <h3><inlineCode parentName="h3">{`LGNDivider`}</inlineCode>{` with Text`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/divider/ios-uikit-divider-with-text.png",
      "alt": "LGNDivider with Text"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let lgnDivider = LGNDivider(
    textDivider: "Sign up"
)
`}</code></pre>
    <h3><inlineCode parentName="h3">{`LGNDivider`}</inlineCode>{` with Custom Colors`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/divider/ios-uikit-divider-with-text-and-colors.png",
      "alt": "LGNDivider with Custom Colors"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let lgnDivider = LGNDivider(
    textDivider: "Sign up",
    textColor: LGNUIColor.primary500,
    dividerLineColor: LGNUIColor.primary500.withAlphaComponent(0.5)
)
`}</code></pre>
    <h3><inlineCode parentName="h3">{`LGNDivider`}</inlineCode>{` with Thickness`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/divider/ios-uikit-divider-with-thickness.png",
      "alt": "LGNDivider with Thickness"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let lgnDivider = LGNDivider(
    dividerThickness: 4.0
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Features`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Text Options:`}</strong>{` Include or exclude text in the divider.`}</li>
      <li parentName="ul"><strong parentName="li">{`Customizable Text Font:`}</strong>{` Set the font size for the text.`}</li>
      <li parentName="ul"><strong parentName="li">{`Divider Thickness:`}</strong>{` Define the thickness of the divider line.`}</li>
      <li parentName="ul"><strong parentName="li">{`Line Color:`}</strong>{` Choose the color of the divider line.`}</li>
      <li parentName="ul"><strong parentName="li">{`Horizontal Padding:`}</strong>{` Adjust padding on either side of the text.`}</li>
    </ul>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dividerHorizontalPadding`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The padding on the left and right sides of the divider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dividerLineColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the divider line itself`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LGNUIColor.tertiary200`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dividerThickness`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The thickness of the divider line`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1.0`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`font`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The font style for the divider’s text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LGNUIFont.bodySmallRegular`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the divider’s text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LGNUIColor.tertiary600`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textDivider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The text displayed within the divider (can be empty if not needed)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      